import React, { FC } from 'react';
import styles from './Header.module.scss';
import Navigation from '../navigation/Navigation';
import logo from '../../../assets/images/logo-gravity-security.png';
import Button from '../../primitives/button/Button';
import MobileNavigation from '../mobile-navigation/MobileNavigation';
import { IActiveMenuHandler } from '../../../utils/common';
import classNames from 'classnames';
import { CONTACT_EMAIL, PLATFORM_URL } from '../../../utils/constants';

const Header: FC<IActiveMenuHandler> = (props) => {
	return (
		<header className={styles.header}>
			<div className={styles.container}>
				<div className={styles.logo}>
					<img src={logo} alt="Gravity Security" />
				</div>

				<Navigation {...props} className={styles.navigation} />

				<MobileNavigation {...props} className={styles['mobile-navigation']} />

				<div className={styles.actions}>
					<Button
						negative
						width={102}
						className={classNames(styles.action, styles.login)}
						href={PLATFORM_URL}
					>
						Log In
					</Button>

					<Button
						width={174}
						className={classNames(styles.action, styles.getGRC)}
						href={`mailto:${CONTACT_EMAIL}`}
					>
						Get GRC Access
					</Button>
				</div>
			</div>
		</header>
	);
};

export default Header;
