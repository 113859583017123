import React, { FC } from 'react';
import styles from './ReadMore.module.scss';
import IReadMore from './IReadMore';
import classNames from 'classnames';

const ReadMore: FC<IReadMore> = ({ className, text, onClickHandler, href }) => {
	const renderText = () => {
		if (href) {
			return (
				<a href={href} className={styles.text}>
					{text || 'Read more'}
				</a>
			);
		}

		return <span className={styles.text}>{text || 'Read more'}</span>;
	};

	return (
		<div className={classNames(styles['read-more'], className)} onClick={onClickHandler}>
			{renderText()}
		</div>
	);
};

export default ReadMore;
