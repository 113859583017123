import React, { FC } from 'react';
import styles from './AboutSection.module.scss';
import classNames from 'classnames';

const AboutSection: FC = () => {
	return (
		<div className={styles.about}>
			<div className={styles.container}>
				<h2>
					Your ultimate
					<br /> compliance powerhouse!
				</h2>

				<div className={styles.content}>
					<div className={classNames(styles.block, styles['top-left'])}>
						<p>
							Founded in 2023, <strong>Gravity Security</strong> is a pioneering GRC
							(Governance, Risk, and Compliance) startup based in the vibrant city of
							Orlando, FL. Established by two seasoned leaders in security and
							compliance, we're driven by a commitment to transform the GRC landscape
							with solutions that propel businesses into higher levels of security and
							compliance.
						</p>
					</div>

					<div className={classNames(styles.block, styles['top-right'])}>
						<p>
							Gravity Security was founded to disrupt the overpriced security industry
							by offering top-tier solutions at a fair price. We prioritize
							understanding each client's unique challenges to deliver tailored,{' '}
							<strong>cost-effective solutions</strong> that keep you{' '}
							<strong>compliant and secure</strong>. Our commitment to excellence
							ensures we stay proactive against emerging threats, empowering you to
							navigate compliance with confidence.
						</p>
					</div>

					<div className={classNames(styles.block, styles['bottom-left'])}>
						<p>
							Our mission at Gravity Security is to deliver affordable,{' '}
							<strong>innovative GRC solutions</strong> that empower businesses of all
							sizes to confidently navigate today's complex compliance environment.
							With a focus on{' '}
							<strong>
								sales enablement, risk mitigation, security enhancement, and
								dedicated advisory
							</strong>
							, we provide tailored strategies that drive compliance and foster trust.
							We are committed to supporting your business success by bringing
							expertise, efficiency, and peace of mind to your GRC journey.
						</p>
					</div>

					<div className={classNames(styles.block, styles['bottom-right'])}>
						<p>
							At Gravity Security, we are dedicated to building lasting partnerships
							with our clients by delivering{' '}
							<strong>efficient, affordable, and impactful GRC solutions</strong>. We
							commit to understanding each client's unique needs and crafting
							strategies that drive meaningful results, empowering businesses to
							achieve security and compliance with confidence. Through our expertise
							and <strong>client-centered approach</strong>, we aim to be a trusted
							partner in your long-term success, helping you navigate the challenges
							of today's complex landscape with ease and assurance.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutSection;
