import React, { FC } from 'react';
import styles from './FeatureListItem.module.scss';
import IFeatureListItem from './IFeatureListItem';
import classNames from 'classnames';

const FeatureListItem: FC<IFeatureListItem> = ({
	className,
	bubbleClassName,
	activeFeature,
	setActiveFeature,
	name,
	description,
	featureKey,
	isPreLast,
	isLast,
	isMultiline,
}) => {
	return (
		<div className={classNames(styles['feature-item'], className)}>
			<div
				className={classNames(
					styles.text,
					activeFeature === featureKey ? styles.active : '',
				)}
				onClick={() => setActiveFeature(featureKey)}
			>
				<span className={styles.line}></span>
				<span
					className={classNames(styles['text-item'], isMultiline ? styles.multiline : '')}
				>
					{name}
				</span>
			</div>

			<div
				className={classNames(
					styles.bubble,
					activeFeature === featureKey ? styles.active : '',
					isPreLast ? styles['pre-last'] : '',
					isLast ? styles.last : '',
					bubbleClassName,
				)}
			>
				<p>{description}</p>
			</div>
		</div>
	);
};

export default FeatureListItem;
