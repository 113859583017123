import styles from './ConsultingSection.module.scss';
import { PageSections } from '../../../utils/constants';

const ConsultingSection = () => {
	const menuItems = [...PageSections];
	menuItems.pop();

	return (
		<div className={styles.consulting}>
			<div className={styles.container}>
				<div className={styles.info}>
					<h2>Consulting</h2>

					<p>
						At Gravity Security, we take the complexity out of security and compliance,
						handling the heavy lifting so you can focus on what matters most: growing
						your business. Our team manages every step, from risk assessments to
						streamlined compliance workflows, ensuring robust security outcomes with
						minimal effort required on your part.
					</p>
				</div>

				<div className={styles.columns}>
					<div className={styles.column}>
						<p>
							Our consulting services span the entire spectrum of security and
							compliance needs—from expert support with Gravity Security products to
							in-depth advisory on compliance initiatives, including internal and
							external audit assistance and pre-3PAO readiness.
						</p>
						<p>
							Our team of highly skilled professionals, with decades of hands-on
							experience, navigates the complexities of security frameworks on your
							behalf, ensuring you stay compliant and confident at every step.
						</p>
					</div>

					<div className={styles.column}>
						<p>
							Whether you are implementing a new security program, designing a secure
							architecture, or integrating advanced security solutions, our
							experienced advisors are here to help you win.
						</p>
						<p>
							Gravity Security is dedicated to delivering practical, cost-effective,
							and tailored recommendations that not only strengthen your security
							posture but also align with your business goals.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConsultingSection;
