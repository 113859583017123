import React, { FC, useState } from 'react';
import styles from './FeaturesList.module.scss';
import FeatureListItem from '../../../primitives/feature-list-item/FeatureListItem';
import Button from '../../../primitives/button/Button';
import { CONTACT_EMAIL } from '../../../../utils/constants';

const FeaturesList: FC<{ scrollToSection: (key: string) => void }> = ({ scrollToSection }) => {
	const [activeFeature, setActiveFeature] = useState<string>('1');

	return (
		<section className={styles['key-features']}>
			<div className={styles.info}>
				<h2>Key Features</h2>

				<p>
					GravityGRC is engineered to empower organizations with a comprehensive,
					intuitive, and scalable platform for managing governance, risk, and compliance.
					Here's an exploration of its standout features:
				</p>
			</div>

			<div className={styles['feature-items']}>
				<FeatureListItem
					featureKey="1"
					activeFeature={activeFeature}
					setActiveFeature={setActiveFeature}
					name={'Break Down Barriers in Sales Pipeline'}
					description={
						'Break down barriers in the sales pipeline with TrustHUB, our all-in-one solution designed to expedite client assurance and boost transparency. Through TrustHUB’s secure, external portal, we streamline communication around information security and privacy, giving clients immediate access to the information they need to feel confident. TrustHUB also enables dynamic document sharing with third parties and external stakeholders, keeping everyone aligned on compliance and privacy standards. This enhanced assurance process not only accelerates sales but builds lasting trust, helping you close deals faster by creating a frictionless, trusted experience for all parties involved.'
					}
					isMultiline
				/>

				<FeatureListItem
					featureKey="2"
					activeFeature={activeFeature}
					setActiveFeature={setActiveFeature}
					name={'Easy Evaluation of Multiple Frameworks'}
					description={
						"Easy evaluation of multiple frameworks using a crosswalk approach is a powerful strategy for organizations aiming to align with various compliance requirements such as NIST, ISO, SOC, GDPR, PCI, HIPAA, and others. By mapping common controls and identifying gaps across these standards upfront, organizations can streamline the compliance preparation process. This proactive approach minimizes redundant efforts, enables a clearer understanding of the level of effort needed, and simplifies alignment with new frameworks as they are introduced. Early crosswalk assessments not only make the journey toward multi-compliance more manageable but also strengthen an organization's overall security posture by creating a cohesive compliance roadmap."
					}
					isMultiline
				/>

				<FeatureListItem
					featureKey="3"
					activeFeature={activeFeature}
					setActiveFeature={setActiveFeature}
					name={'Granular Control Capabilities'}
					description={
						'Granular control capabilities enable organizations to easily update controls and navigate approval workflows with efficiency. This high level of control flexibility allows compliance teams to adapt swiftly to regulatory changes, minimizing both time and resource commitments. Additionally, the platform’s natural language control implementation guidance simplifies compliance efforts by clearly outlining the steps needed to meet each objective. Together, these features make control management straightforward and adaptive, allowing organizations to maintain a proactive compliance posture with minimal manual effort.'
					}
				/>

				<FeatureListItem
					featureKey="4"
					activeFeature={activeFeature}
					setActiveFeature={setActiveFeature}
					name={'Automated AI-powered Onboarding'}
					description={
						'Automated AI-powered onboarding simplifies the process by using your existing security program or building one from scratch if needed. With flexible onboarding flows, it adapts to various organizational needs, whether extending, transferring, or establishing a new program. This seamless and secure approach gives organizations a quick start, providing a strong foundation that supports a resilient and comprehensive information security program.'
					}
				/>

				<FeatureListItem
					featureKey="5"
					activeFeature={activeFeature}
					setActiveFeature={setActiveFeature}
					name={'Dynamic Policy Management'}
					description={
						'Dynamic Policy Management empowers organizations to stay agile in their compliance efforts with real-time policy updates, automated approval workflows, and instant policy generation. By centralizing policy management and streamlining processes, GravityGRC reduces the time and effort required to maintain up-to-date policies aligned with current regulations.'
					}
				/>

				<FeatureListItem
					featureKey="6"
					activeFeature={activeFeature}
					setActiveFeature={setActiveFeature}
					name={'Turbo Audit Readiness'}
					bubbleClassName={styles['big-bubble']}
					description={
						'Turbo Audit Readiness designed to help organizations navigate the audit process with ease, providing a streamlined approach that reduces time and complexity in achieving compliance. With the support of the included Gravity Security Advisory Service, your team benefits from expert guidance through each phase of the audit, from initial preparation to final review. This seamless approach minimizes friction, helping you meet audit requirements confidently and efficiently, while setting a strong foundation for certification.'
					}
				/>

				<FeatureListItem
					isPreLast
					featureKey="7"
					activeFeature={activeFeature}
					setActiveFeature={setActiveFeature}
					name={'Elite Advisory'}
					description={
						'Elite Advisory providing clients with on-demand, expert support for every audit event, question, or project—all free of charge. This hands-on advisory approach ensures that clients have immediate access to experienced guidance whenever they need it, whether for audit preparation, addressing specific compliance inquiries, or managing project milestones. With the Advisory Service at your fingertips, navigating the complexities of audits becomes seamless, empowering organizations to achieve compliance confidently and efficiently.'
					}
				/>

				<FeatureListItem
					isLast
					featureKey="8"
					activeFeature={activeFeature}
					setActiveFeature={setActiveFeature}
					name={'Protect your business'}
					description={
						'GravityGRC empowers you to safeguard data, manage risks, and protect client information by identifying weaknesses and compliance gaps. With targeted guidance and automated processes that enable swift responses and efficient gap closure, the platform strengthens your risk management strategy and streamlines compliance efforts. GravityGRC is designed to simplify the security & compliance landscape—one organization at a time.'
					}
				/>
			</div>

			<Button width={174} className={styles.action} href={`mailto:${CONTACT_EMAIL}`}>
				Get GRC Access
			</Button>
		</section>
	);
};

export default FeaturesList;
