import React, { FC } from 'react';
import styles from './ContactsSection.module.scss';
import logo from '../../../assets/images/logo-gravity-security.png';
import { IActiveMenuHandler } from '../../../utils/common';
import { CONTACT_EMAIL, PageSections } from '../../../utils/constants';

const ContactsSection: FC<IActiveMenuHandler> = ({ scrollToSection }) => {
	const menuItems = [...PageSections];
	menuItems.pop();

	return (
		<div className={styles.contacts}>
			<div className={styles.container}>
				<div className={styles['logo-section']}>
					<div className={styles.logo}>
						<img src={logo} alt="Gravity Security" width={100} />
					</div>

					<div className={styles.rights}>
						© {new Date().getFullYear()} Gravity Security, LLC. All Rights Reserved.
					</div>
				</div>

				<nav className={styles['bottom-navigation']}>
					{menuItems.map((section) => (
						<span
							key={section.key}
							className={styles.item}
							onClick={() => scrollToSection(section.key)}
						>
							{section.displayName}
						</span>
					))}
				</nav>

				<div className={styles.contact}>
					<a href={`mailto:${CONTACT_EMAIL}`} className={styles.item}>
						{CONTACT_EMAIL}
					</a>
				</div>

				<div className={styles['rights-mobile']}>
					© {new Date().getFullYear()} Gravity Security, LLC. All Rights Reserved.
				</div>
			</div>
		</div>
	);
};

export default ContactsSection;
