import React, { FC } from 'react';
import styles from './TopSection.module.scss';

const TopSection: FC = () => {
	return (
		<div className={styles.top}>
			<div className={styles.content}>
				<div className={styles.info}>
					<h1>
						GravityGRC -
						<span className={styles.highlighted}>Effortless Compliance</span>
					</h1>

					<p>
						GravityGRC combines an AI-driven platform with dedicated advisory services
						to transform Governance, Risk and Compliance Management. Our solution
						empowers companies of all sizes to streamline Security Program Management,
						achieve certifications, and unlock sales opportunities—without the high
						costs of the existing tools. With expert support from Gravity Security
						Advisory built in, we partner with you to remove friction at every step.
					</p>
				</div>

				<div className={styles.frameworks}></div>
			</div>
		</div>
	);
};

export default TopSection;
