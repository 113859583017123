export const PageSectionKeys = {
	home: 'home',
	trustHub: 'trustHub',
	trustHubPortal: 'trustHubPortal',
	consulting: 'consulting',
	about: 'about',
	contacts: 'contacts',
};

export const PageSections = [
	{
		displayName: 'Gravity GRC',
		key: PageSectionKeys.home,
	},
	{
		displayName: 'TrustHUB',
		key: PageSectionKeys.trustHub,
	},
	{
		displayName: 'Consulting',
		key: PageSectionKeys.consulting,
	},
	{
		displayName: 'About Us',
		key: PageSectionKeys.about,
	},
	{
		displayName: 'Contacts',
		key: PageSectionKeys.contacts,
	},
];

export const CONTACT_EMAIL = 'info@gravity-security.com';
export const PLATFORM_URL = 'https://platform.gravitygrc.com/login';
