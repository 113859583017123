import { useEffect, useState } from 'react';
import { PageSectionKeys } from '../constants';

export default function useScrollableNavigation() {
	const [activeMenuItem, setActiveMenuItem] = useState(PageSectionKeys.home);

	const scrollToSection = (section: string) => {
		if (section === PageSectionKeys.home) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			return;
		}

		if (section === PageSectionKeys.trustHub) {
			const trustHubPortal = document.getElementById(PageSectionKeys.trustHubPortal);
			if (!trustHubPortal) return;

			trustHubPortal.style.scrollMargin = '150px';
			trustHubPortal.scrollIntoView({ behavior: 'smooth', block: 'start' });
			return;
		}

		if (section === PageSectionKeys.consulting) {
			const consultingSection = document.getElementById(PageSectionKeys.consulting);
			if (!consultingSection) return;

			consultingSection.style.scrollMargin = '60px';
			consultingSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
			return;
		}

		const element = document.getElementById(section);
		if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' });
	};

	useEffect(() => {
		window.history.scrollRestoration = 'manual';
	}, []);

	useEffect(() => {
		const handleScroll = () => {
			const sections = document.querySelectorAll('[data-landing-section]');

			const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
			const scrollPosition = window.scrollY || document.documentElement.scrollTop;

			if (scrollPosition + 10 >= scrollableHeight) {
				setActiveMenuItem(PageSectionKeys.contacts);
				return;
			}

			sections.forEach((section) => {
				const sectionTop = section.getBoundingClientRect().top + scrollPosition - 100;
				const sectionBottom = sectionTop + section.clientHeight - 100;

				if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
					setActiveMenuItem(section.id);
				}
			});
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return {
		activeMenuItem,
		scrollToSection,
	};
}
